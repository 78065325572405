import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import banner from './images/banner.jpg';
import biblioteca from '../../../../data/biblioteca.json';
import { Material } from './Material';
import { SectionImage } from '../../../ui/sections/SectionImage';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import { useSelector } from 'react-redux';
import axios from 'axios';
import tolmex from '../biblioteca_digital/images/logo_tolmex.png';
// import ebsco from '../biblioteca_digital/images/ebscohost-logo-color-screen-stacked.png';
import { getToken } from '../../../../utils/token';

export const BibliotecaDigital = ({ isAuthenticated }) => {
  useTitle('Biblioteca CEEAD');
  useGoogleAnalytics();
  const user = useSelector((state) => state.auth);
  const { t } = useTranslation('bibliotecaDigital');
  const [seleccion, setSeleccion] = useState({ categoria: 'herramientas', submenu: 'entrada' });
  const handleChangeSeleccion = (seleccion) => {
    setSeleccion({ categoria: seleccion.categoria, submenu: seleccion.submenu });
  };
  let showbutton = false;
  const useremail = Object.keys(user).length ? user.email : null;
  if (useremail) {
    const blacklistemail = [];
    const blacklistdomain = [];
    const whitelistemail = [
      // 'carlosparedes@gmail.com',
      'admin@local.host',
      'jgerardomoralesbermudez@gmail.com',
      'luismr@ceead.org.mx',
      'aliciazapata13@hotmail.com',
      'aidee.lara.juarez@gmail.com',
      'monica@zarateabogados.com',
      'ricardout@icloud.com',
      'carmengoc5593@hotmail.com',
      'reinsworld@hotmail.com',
      'mahuesca@yahoo.com',
      'pauletterp@ceead.org.mx',
      'sergiotrev94@gmail.com',
      'licdavidcastromedina@gmail.com',
      'rojasiuris3@hotmail.com',
      'lgvillasenor@fldm.edu.mx',
      'maricruz.b.mena@gmail.com',
      'burgosaguilarleandro@gmail.com',
      'fortegon@marista.edu.mx',
      'usuarez@um.edu.mx',
      'silvanasantos17@hotmail.com',
      'miguel@zarateabogados.com',
      'jmrpuvm2005@hotmail.com',
      'agrobles00@gmail.com',
      'maria.silva@u-erre.edu.mx',
      'danielagaleana25@gmail.com',
      'magomezpartida@gmail.com',
      'sara.velo77@gmail.com',
      'marysoldelrio@iteso.mx',
      'elis.tol97@gmail.com',
      'leav@tec.mx',
      'pattyquintanar@me.com',
      'adrian_unam@yahoo.com.mx',
      'glorialisa2000@yahoo.com.mx',
      'stephaniecalvillo@iteso.mx',
      'luisjavier.cardenas@gmail.com',
      'chavasalas@hotmail.com',
      'yess7019@hotmail.es',
      'chapaser@hotmail.com',
      'rall_1130@hotmail.com',
      'hectorsamuelgdl@gmail.com',
      'pedrojafet10@hotmail.com',
      'cristyloaeza@hotmail.com',
      'ivan.martinez@bgyasociados.com.mx',
      'enriquetab@iteso.mx',
      'camygoc@gmail.com',
      'elizabeth.mendoza@uaq.mx',
      'narufredo@gmail.com',
    ];
    const whitelistdomain = ['ceead.org.mx'];
    if (blacklistemail.includes(useremail) || blacklistdomain.includes(useremail.split('@').pop())) {
      showbutton = false;
    }
    if (whitelistemail.includes(useremail) || whitelistdomain.includes(useremail.split('@').pop())) {
      showbutton = true;
    }
  }

  const handleTicketProvider = () => {
    const token = getToken();
    axios
      .get(`https://api.ceead.org.mx/v1/tolmex-ticket-provider/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        window.open(res.data.ticket, '_blank');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* const handleEbscoHost = () => {
    window.open(
      'https://search.ebscohost.com/login.aspx?authtype=ip,uid&custid=ns314783&groupid=main&profile=ehost',
      '_blank',
    );
  }; */

  const menu = [
    {
      name: 'herramientas',
      submenu: [
        { name: 'entrada', i18n: 'cat7' },
        { name: 'Lineamiento', i18n: 'cat1' },
        { name: 'Manual', i18n: 'cat2' },
        { name: 'Silabus', i18n: 'cat3' },
        { name: 'Infografia', i18n: 'cat4' },
        { name: 'Informe', i18n: 'cat5' },
        { name: 'Otro', i18n: 'cat6' },
        { name: 'Investigación', i18n: 'cat18' },
        { name: 'CasosGuías', i18n: 'cat19' },
      ],
    },
    {
      name: 'videoteca',
      submenu: [
        { name: 'entrada', i18n: 'cat7' },
        { name: 'Aula', i18n: 'cat8' },
        { name: 'Cejume', i18n: 'cat9' },
        { name: 'Congreso', i18n: 'cat10' },
        { name: 'Cultura', i18n: 'cat11' },
        { name: 'Exsipa', i18n: 'cat12' },
        { name: 'Institucional', i18n: 'cat13' },
        { name: 'Observatorio', i18n: 'cat14' },
        { name: 'Semilla', i18n: 'cat15' },
        { name: 'Reforma', i18n: 'cat16' },
        { name: 'Indigenas', i18n: 'cat17' },
      ],
    },
  ];

  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={banner}>
          <div className="emphasis-title text-left">
            <h2 className="font-weight-bold">{t('contenido.title')}</h2>

            <h4>{t('contenido.bannerTxt')}</h4>
          </div>
        </SectionImage>
        <div className="container pb-5 clearfix">
          <div className="row">
            <div className="col-12">
              <p>{t('contenido.txt1')}</p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-3" />
            <div className="col-9">
              <p className="text-center">
                {showbutton && (
                  <img
                    className="shadow-lg mx-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleTicketProvider()}
                    src={tolmex}
                    alt="Tirant Online"
                  />
                )}
              </p>
              <hr />
              <p className="text-center">
                {menu.map((item) => (
                  <button
                    key={item.name}
                    type="button"
                    onClick={() => handleChangeSeleccion({ categoria: item.name, submenu: 'entrada' })}
                    className={
                      seleccion.categoria === item.name
                        ? 'btn btn-danger mr-3 mb-3'
                        : 'btn btn-outline-danger mr-3 mb-3'
                    }
                  >
                    {t(`contenido.${item.name}`)}
                  </button>
                ))}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <div id="shortcodes" className="widget widget_links clearfix pb-5">
                {menu.map(
                  (item) =>
                    seleccion.categoria === item.name && (
                      <ul>
                        {item.submenu.map((subitem, index) => (
                          <li key={index}>
                            <div
                              onClick={() => setSeleccion({ ...seleccion, submenu: subitem.name })}
                              className={seleccion.submenu === subitem.name ? 'bd ml-2 bd-active' : 'bd ml-2'}
                            >
                              {t(`contenido.${subitem.i18n}`)}
                            </div>
                          </li>
                        ))}
                      </ul>
                    ),
                )}
              </div>
            </div>
            <div className="col-12 col-md-9">
              {biblioteca
                .find((seccion) => seccion.categoria === seleccion.categoria)
                .proyectos.map((proyecto) => (
                  <div className="row" key={proyecto.id}>
                    {proyecto.materiales
                      .filter((material) => seleccion.submenu === 'entrada' || seleccion.submenu === material.tipo)
                      .map((material, index) => (
                        <div className="col-12 col-sm-6 col-lg-4" key={index}>
                          <Material material={material} key={material.id} isAuthenticated={isAuthenticated} />
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

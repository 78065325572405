import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import { useSelector } from 'react-redux';
import * as analytics from '../../../../helpers/analytics';
import axios from 'axios';
import { API_URL } from '../../../../settings/settings';

export const Material = ({ material }) => {
  useTitle(`Material Biblioteca CEEAD: ${material.nombre}`);
  const user = useSelector((state) => state.auth);
  const { t } = useTranslation('bibliotecaDigital');
  const [downloadState] = useState({ errors: '', loading: false });
  const url = material.material;

  const handleDownloadFile = () => {
    analytics.init();
    analytics.sendEvent({
      category: 'Biblioteca CEEAD',
      action: 'Downloaded',
      label: material.nombre,
      value: user.id,
    });
    const data = {
      nombre_manual: material.nombre,
      usuario_id: user.id,
    };
    axios
      .post(`${API_URL}/v1/manuales-usuarios/`, data)
      .then((response) => {})
      .catch(() => {})
      .finally(() => {});
    const a = document.createElement('a');
    a.href = url;
    a.download = '';
    a.target = '_blank';
    a.rel = 'noreferrer';
    a.click();
    a.remove();
  };
  return (
    <div className="card material-card border-0 pb-3">
      <img src={material.portada} className="card-img-top rounded shadow w-100 mb-2" alt={material.nombre} />
      <div className="card-body">
        <h5 className="card-title mb-2" style={{ fontSize: '1.1rem' }}>{` ${material.nombre}`}</h5>
        <p>
          {user?.id ? (
            <button disabled={downloadState.loading} className="btn btn-block btn-success" onClick={handleDownloadFile}>
              {downloadState.loading ? t('material.botonDescargando') : t('material.botonDescarga')}
              {downloadState.loading ? (
                <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" />
              ) : (
                <i className="icon-download ml-2" />
              )}
            </button>
          ) : (
            // <a href={ url } className="btn btn-block btn-success" target="_blank" rel="noopener noreferrer" download>{ t('material.botonDescarga') } <i className="icon-download ml-2" /></a>
            <span>{t('material.botonRegistro')}</span>
          )}
        </p>
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from './images/logo-ceead-full.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ACCOUNTS_PATH, LOGIN_PATH, REGISTER_PATH } from '../../settings/settings';
import { logout } from '../../actions/auth';
import { removeToken } from '../../utils/token';
import useMobile from '../../hooks/useMobile';

export const Navbar = () => {
  const { t, i18n } = useTranslation('navbar');
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isInTop, setIsInTop] = useState(window.scrollY === 0);
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useMobile().isMobile;
  const LoggedIn = Object.keys(user).length;

  const onScroll = () => {
    setIsInTop(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    setIsVisible(false);
  }, [isMobile]);

  useEffect(() => {
    isVisible ? document.body.classList.add('overlay--open') : document.body.classList.remove('overlay--open');
  }, [isVisible]);

  const handleChangeLanguage = () => {
    const language = i18n.language === 'es' ? 'en' : 'es';
    i18n.changeLanguage(language).then(() => {});
  };

  const handleLogout = () => {
    removeToken();
    dispatch(logout);
    history.go(0);
  };
  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const NavItem = ({ children, dropdown }) => {
    return (
      <li
        className="nav-item"
        onClick={() => {
          isMobile && !dropdown && toggleMenu();
        }}
      >
        {children}
      </li>
    );
  };

  const Dropdown = ({ children, navitem }) => {
    const [isOpened, setIsOpened] = useState(false);
    return (
      <>
        {isMobile ? (
          <div className="dropdown__title-container" onClick={() => setIsOpened(!isOpened)}>
            {navitem}
            <span className={`dropdown__arrow${isOpened ? ' dropdown__arrow--active' : ''}`}>
              <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1.5L9 9.5L17 1.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        ) : (
          navitem
        )}
        <div className={`nav-dropdown${isMobile && isOpened ? ' nav-dropdown--active' : ''}`}>
          <ul>{children}</ul>
        </div>
      </>
    );
  };

  const DropdownLink = ({ to, translate }) => {
    return (
      <li className="nav-dropdown__item" onClick={() => isMobile && toggleMenu()}>
        <Link to={to}>{t(translate)}</Link>
      </li>
    );
  };

  const Avatar = () => {
    const getInitials = (string) => {
      const names = string.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    };

    return (
      <div className="avatar__container">
        <div>{getInitials(user.displayName)}</div>
      </div>
    );
  };

  const LangSection = () => {
    return (
      <div className="lang-icon" onClick={handleChangeLanguage}>
        <span className="">
          <span className={i18n.language === 'es' ? 'flag-icon flag-icon-us' : 'flag-icon flag-icon-mx'} />{' '}
          {i18n.language === 'es' ? 'EN' : 'ES'}
        </span>
      </div>
    );
  };

  const NavMenu = () => {
    return (
      <ul>
        {/* <NavItem dropdown>
          <Dropdown navitem={<span>{t('perfilSeleccion')}</span>}>
            <DropdownLink to="/perfiles/estudiantes" translate="estudiantes" />
            <DropdownLink to="/perfiles/docentes" translate="docentes" />
            <DropdownLink to="/perfiles/personal-operador" translate="personalOperador" />
            <DropdownLink to="/perfiles/personal-investigador" translate="personalInvestigador" />
          </Dropdown>
        </NavItem> */}
        <NavItem dropdown>
          <Dropdown navitem={<Link to="/quienes-somos">{t('quienesSomos.titulo')}</Link>}>
            <DropdownLink to="/quienes-somos/equipo-ceead" translate="quienesSomos.equipoCeead" />
            <DropdownLink to="/quienes-somos/consejo" translate="quienesSomos.consejo" />
            {/* <DropdownLink to="/quienes-somos/bolsa-trabajo" translate="quienesSomos.bolsaTrabajo" /> */}
            {/* <DropdownLink to="/quienes-somos/alianzas" translate="quienesSomos.alianzas" /> */}
          </Dropdown>
        </NavItem>
        <NavItem dropdown>
          <Dropdown navitem={<span>{t('comoTransformamos.titulo')}</span>}>
            <DropdownLink
              to="/como-transformamos/herramientas-diagnostico"
              translate="comoTransformamos.herramientasDiagnostico"
            />
            <DropdownLink to="/como-transformamos/investigacion" translate="comoTransformamos.investigacion" />
            <DropdownLink to="/como-transformamos/biblioteca-digital" translate="comoTransformamos.biblioteca" />
            <DropdownLink to="/como-transformamos/evaluacion" translate="comoTransformamos.evaluacion" />
            <DropdownLink
              to="/como-transformamos/capacitacion-y-formacion"
              translate="comoTransformamos.capacitacion"
            />
            <DropdownLink to="/como-transformamos/eventos-institucionales" translate="comoTransformamos.eventos" />
            <DropdownLink to="/como-transformamos/redes-colaboracion" translate="comoTransformamos.redes" />
          </Dropdown>
        </NavItem>
        <NavItem>
          <Link to="/noticias">{t('noticias')}</Link>
        </NavItem>
        <NavItem>
          <Link to="/blog">{t('blog')}</Link>
        </NavItem>
        <NavItem>
          <Link to="/contacto">{t('contacto')}</Link>
        </NavItem>
      </ul>
    );
  };

  return (
    <>
      {isVisible && <div className="overlay" onClick={toggleMenu} />}
      <div className="header-gap" />
      <header className={`header${isInTop ? '' : ' header--fixed'}`}>
        <div className="header__container">
          <div className="header__logo">
            <Link to="/">
              <img src={logo} alt="ceead-logo" />
            </Link>
          </div>
          {!isMobile && (
            <nav className="header__nav">
              <NavMenu />
            </nav>
          )}
        </div>
        <div className="header__container">
          {isMobile ? (
            <div onClick={toggleMenu} className="header__burguer-menu">
              Menú
              <span>
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 10.25C0.585786 10.25 0.25 10.5858 0.25 11C0.25 11.4142 0.585786 11.75 1 11.75V10.25ZM17 11.75C17.4142 11.75 17.75 11.4142 17.75 11C17.75 10.5858 17.4142 10.25 17 10.25V11.75ZM1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75V5.25ZM17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25V6.75ZM1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM17 1.75C17.4142 1.75 17.75 1.41421 17.75 1C17.75 0.585786 17.4142 0.25 17 0.25V1.75ZM1 11.75H17V10.25H1V11.75ZM1 6.75H17V5.25H1V6.75ZM1 1.75H17V0.25H1V1.75Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          ) : (
            <div className="header__container">
              <LangSection />
              {LoggedIn ? (
                <div className="nav-item">
                  <span>{user.displayName}</span>
                  <div className="nav-dropdown">
                    <ul>
                      <li className="nav-dropdown__item" onClick={() => handleLogout()}>
                        <span>{t('salir')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <a className="header__login-btn" href={ACCOUNTS_PATH + LOGIN_PATH} rel="noreferrer" target="_self">
                  {t('iniciarSesion')}
                  <span className="rb-header-button-arrow">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </a>
              )}
            </div>
          )}
        </div>
      </header>
      {isMobile && (
        <aside className={`aside__menu${isVisible ? '' : ' aside__menu--hidden'}`}>
          <div className="aside__header">
            <div onClick={toggleMenu} className="header__burguer-menu">
              Menú
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5303 1.53033C15.8232 1.23744 15.8232 0.762563 15.5303 0.46967C15.2374 0.176777 14.7626 0.176777 14.4697 0.46967L15.5303 1.53033ZM0.46967 14.4697C0.176777 14.7626 0.176777 15.2374 0.46967 15.5303C0.762563 15.8232 1.23744 15.8232 1.53033 15.5303L0.46967 14.4697ZM1.53034 0.46967C1.23745 0.176777 0.762577 0.176777 0.469684 0.46967C0.176791 0.762563 0.176791 1.23744 0.469684 1.53033L1.53034 0.46967ZM14.4697 15.5303C14.7626 15.8232 15.2375 15.8232 15.5304 15.5303C15.8232 15.2374 15.8232 14.7626 15.5304 14.4697L14.4697 15.5303ZM14.4697 0.46967L0.46967 14.4697L1.53033 15.5303L15.5303 1.53033L14.4697 0.46967ZM0.469684 1.53033L14.4697 15.5303L15.5304 14.4697L1.53034 0.46967L0.469684 1.53033Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            {LoggedIn ? (
              <div className="aside__user">
                <Avatar />
                <div>{user.displayName}</div>
              </div>
            ) : (
              ''
            )}
          </div>
          <nav className="aside__menu-nav">
            <NavMenu />
            <LangSection />
          </nav>
          <div className="aside__footer">
            {LoggedIn ? (
              <div className="aside__register-btn" onClick={handleLogout}>
                <span>
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.5 0.25C12.0858 0.25 11.75 0.585786 11.75 1C11.75 1.41421 12.0858 1.75 12.5 1.75V0.25ZM12.5 16.25C12.0858 16.25 11.75 16.5858 11.75 17C11.75 17.4142 12.0858 17.75 12.5 17.75V16.25ZM6.03033 5.53033C6.32322 5.23744 6.32322 4.76256 6.03033 4.46967C5.73744 4.17678 5.26256 4.17678 4.96967 4.46967L6.03033 5.53033ZM1.5 9L0.96967 8.46967C0.829018 8.61032 0.75 8.80109 0.75 9C0.75 9.19891 0.829018 9.38968 0.96967 9.53033L1.5 9ZM4.96967 13.5303C5.26256 13.8232 5.73744 13.8232 6.03033 13.5303C6.32322 13.2374 6.32322 12.7626 6.03033 12.4697L4.96967 13.5303ZM13.5 9.75C13.9142 9.75 14.25 9.41421 14.25 9C14.25 8.58579 13.9142 8.25 13.5 8.25V9.75ZM12.5 1.75H15.5V0.25H12.5V1.75ZM16.75 3V15H18.25V3H16.75ZM15.5 16.25H12.5V17.75H15.5V16.25ZM16.75 15C16.75 15.6904 16.1904 16.25 15.5 16.25V17.75C17.0188 17.75 18.25 16.5188 18.25 15H16.75ZM15.5 1.75C16.1904 1.75 16.75 2.30964 16.75 3H18.25C18.25 1.48122 17.0188 0.25 15.5 0.25V1.75ZM4.96967 4.46967L0.96967 8.46967L2.03033 9.53033L6.03033 5.53033L4.96967 4.46967ZM0.96967 9.53033L4.96967 13.5303L6.03033 12.4697L2.03033 8.46967L0.96967 9.53033ZM1.5 9.75H13.5V8.25L1.5 8.25L1.5 9.75Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {t('salir')}
              </div>
            ) : (
              <>
                <a className="aside__register-btn" href={ACCOUNTS_PATH + REGISTER_PATH} rel="noreferrer" target="_self">
                  {t('registro')}
                </a>
                <a className="aside__login-btn" href={ACCOUNTS_PATH + LOGIN_PATH} rel="noreferrer" target="_self">
                  {t('iniciarSesion')}
                </a>
              </>
            )}
          </div>
        </aside>
      )}
    </>
  );
};

import { useTranslation } from 'react-i18next';
import { FormularioBoletin } from './FormularioBoletin';
import ScrollUpButton from './ScrollUpButton';
import logo from './images/logo-min.svg';
import { Link } from 'react-router-dom';
import useMobile from '../../hooks/useMobile';

export const Footer = () => {
  const isTablet = useMobile().isTablet;

  const SocialLinks = () => {
    return (
      <div className="social">
        <a href="https://www.facebook.com/ceead.org.mx" rel="noopener noreferrer" target="_blank">
          <i className="icon-facebook-square" />
        </a>
        <a href="https://www.linkedin.com/company/ceead-educacion-juridica/" rel="noopener noreferrer" target="_blank">
          <i className="icon-linkedin" />
        </a>
        <a href="https://www.instagram.com/ceead.org.mx/" rel="noopener noreferrer" target="_blank">
          <i className="icon-instagram" />
        </a>
        <a href="https://twitter.com/CEEAD" rel="noopener noreferrer" target="_blank">
          <svg
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '18px', verticalAlign: 'baseline' }}
          >
            <title>X</title>
            <path
              fill="currentColor"
              d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
            />
          </svg>
        </a>
        <a href="https://www.youtube.com/user/ceead1" rel="noopener noreferrer" target="_blank">
          <i className="icon-youtube" />
        </a>
      </div>
    );
  };

  const { t } = useTranslation('footer');
  return (
    <>
      <FormularioBoletin />
      <footer>
        <div className="footer__grid">
          <div className="links">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div>
              <h4>Enlaces de interés</h4>
              <ul>
                <li>
                  <a href="https://aulaceead.mx" target="_blank" rel="noreferrer">
                    Aula CEEAD
                  </a>
                </li>
                <li>
                  <a href="https://cejume.mx" target="_blank" rel="noreferrer">
                    CEJUME
                  </a>
                </li>
                <li>
                  <a href="https://congreso.ceead.org.mx" target="_blank" rel="noreferrer">
                    Congreso CEEAD
                  </a>
                </li>
                <li>
                  <a href="https://especialidad.ceead.org.mx/" target="_blank" rel="noreferrer">
                    Especialidad en Educación Jurídica
                  </a>
                </li>
                <li>
                  <a href="https://observatorioceead.mx" target="_blank" rel="noreferrer">
                    Observatorio CEEAD
                  </a>
                </li>
                <li>
                  <a href="https://portafoliosceead.mx" target="_blank" rel="noreferrer">
                    Portafolios CEEAD
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="platform">
            <h4>Plataforma</h4>
            <ul>
              <li>
                <Link to="/apoya-ceead">{t('apoyaCeead')}</Link>
              </li>
              <li>
                <a href="https://hdceead.freshdesk.com/" target="_blank" rel="noreferrer">
                  {t('mesaAyuda')}
                </a>
              </li>
              <li>
                <Link to="/legales/aviso-privacidad">{t('links.privacy')}</Link>
              </li>
              <li>
                <Link to="/legales/terminos-y-condiciones">{t('links.terms')}</Link>
              </li>
            </ul>
          </div>
          <div className="contact">
            <h4>Contacto</h4>
            <ul>
              <li>
                <a href="mailto:info@ceead.org.mx">info@ceead.org.mx</a>
              </li>
              <li>
                <a href="tel:+52 (81) 2055-0628">+52 (81) 2055-0628</a>
              </li>
              <li>
                <a href="tel:+52 (81) 1766-1198">+52 (81) 1766-1198</a>
              </li>
            </ul>
            {!isTablet && <SocialLinks />}
          </div>
        </div>
        <div className="copyright">
          {isTablet && <SocialLinks />}
          <p>
            Miguel Nieto 440 Sur, Colonia Centro, Monterrey, NL C.P. 64000 <br />©{new Date().getFullYear()}. Centro de
            Estudios sobre la Enseñanza y el Aprendizaje del Derecho, A.C.
          </p>
        </div>
        <ScrollUpButton />
      </footer>
    </>
  );
};

import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { ACCOUNTS_PATH, API_URL, LOGIN_PATH, REGISTER_PATH } from '../settings/settings';
import ScrollToTop from '../components/ui/ScrollToTop';
import { Home } from '../components/pages/home/Home';
import { Navbar } from '../components/navbar/Navbar';
import { Footer } from '../components/footer/Footer';
import { QuienesSomos } from '../components/pages/quienes_somos/QuienesSomos';
import { Noticias } from '../components/pages/noticias/Noticias';
import { Seminario } from '../components/pages/como_transformamos/investigacion/seminario/Seminario';
import { Investigacion } from '../components/pages/como_transformamos/investigacion/Investigacion';
import { CapacitacionFormacion } from '../components/pages/como_transformamos/capacitacion_formacion/CapacitacionFormacion';
// import { CalendarioCeead } from '../components/pages/calendario_ceead/CalendarioCeead';
import { ApoyaCeead } from '../components/pages/apoya_ceead/ApoyaCeead';
import { HerramientasDiagnostico } from '../components/pages/como_transformamos/herramientas_diagnostico/HerramientasDiagnostico';
import { PreguntasFrecuentes } from '../components/pages/preguntas_frecuentes/PreguntasFrecuentes';
import { RedesColaboracion } from '../components/pages/como_transformamos/redes_colaboracion/RedesColaboracion';
import { BibliotecaDigital } from '../components/pages/como_transformamos/biblioteca_digital/BibliotecaDigital';
import { EquipoCeead } from '../components/pages/quienes_somos/equipo_ceead/EquipoCeead';
import { EvaluacionCertificacion } from '../components/pages/como_transformamos/evaluacion_certificacion/EvaluacionCertificacion';
import { EventosInstitucionales } from '../components/pages/como_transformamos/eventos_institucionales/EventosInstitucionales';
import { PerfilesHistoricos } from '../components/pages/como_transformamos/investigacion/perfiles_historicos/PerfilesHistoricos';
// import { BolsaTrabajo } from '../components/pages/quienes_somos/bolsa_trabajo/BolsaTrabajo';
// import { Alianzas } from '../components/pages/quienes_somos/alianzas/Alianzas';
import { Consejo } from '../components/pages/quienes_somos/consejo/Consejo';
import { AvisoPrivacidad } from '../components/pages/legales/aviso_privacidad/AvisoPrivacidad';
import { TerminosCondiciones } from '../components/pages/legales/terminos_condiciones/TerminosCondiciones';
import { Contacto } from '../components/pages/contacto/Contacto';
import { Donaciones } from '../components/pages/apoya_ceead/donaciones/Donaciones';
import { Legales } from '../components/pages/legales/Legales';
// import { PerfilEstudiante } from '../components/pages/perfiles/PerfilEstudiante';
// import { PerfilDocentes } from '../components/pages/perfiles/PerfilDocentes';
// import { PerfilPersonalOperador } from '../components/pages/perfiles/PerfilPersonalOperador';
// import { PerfilPersonalInvestigador } from '../components/pages/perfiles/PerfilPersonalInvestigador';
import { VerificarSuscripcionBoletin } from '../components/pages/boletin/VerificarSuscripcionBoletin';
import { useDispatch } from 'react-redux';
import { login, logout } from '../actions/auth';
import axios from 'axios';
import { getToken } from '../utils/token';
import { ScaleLoader } from 'react-spinners';
import { Blog } from '../components/pages/blog/Blog';
import { Post } from '../components/pages/blog/Post';
import { PoliticaReembolsosCancelaciones } from '../components/pages/legales/politica-reembolsos-cancelaciones/PoliticaReembolsosCancelaciones';

export const AppRouter = () => {
  const [checking, setChecking] = useState(true);
  const dispatch = useDispatch();
  const token = getToken();
  useEffect(() => {
    axios
      .post(
        `${API_URL}/v1/auth/login/test-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        dispatch(login(response.data.id, response.data.nombre, response.data.type, response.data.email));
      })
      .catch(() => {
        dispatch(logout);
      })
      .finally(() => {
        setChecking(false);
      });
  }, [dispatch, token]);

  if (checking) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate3d(-50%,-50%,0)' }}>
        <ScaleLoader height={35} width={4} radius={2} margin={2} color="#af1118" />
      </div>
    );
  }

  return (
    <Router>
      <>
        <ScrollToTop />
        <Navbar />
        <Switch>
          {/* <PublicRoute exact path="/perfiles/estudiantes" component={PerfilEstudiante} />
          <PublicRoute exact path="/perfiles/docentes" component={PerfilDocentes} />
          <PublicRoute exact path="/perfiles/personal-operador" component={PerfilPersonalOperador} />
          <PublicRoute exact path="/perfiles/personal-investigador" component={PerfilPersonalInvestigador} /> */}

          <PublicRoute exact path="/" component={Home} />
          <PublicRoute exact path="/apoya-ceead" component={ApoyaCeead} />
          <PublicRoute exact path="/apoya-ceead/donar" component={Donaciones} />

          <PublicRoute exact path="/quienes-somos" component={QuienesSomos} />
          <PublicRoute exact path="/quienes-somos/equipo-ceead" component={EquipoCeead} />
          <PublicRoute exact path="/quienes-somos/consejo" component={Consejo} />
          {/* <PublicRoute exact path="/quienes-somos/alianzas" component={Alianzas} /> */}
          {/* <PublicRoute exact path="/quienes-somos/bolsa-trabajo" component={BolsaTrabajo} /> */}

          <PublicRoute exact path="/como-transformamos/herramientas-diagnostico" component={HerramientasDiagnostico} />
          <PublicRoute exact path="/como-transformamos/investigacion" component={Investigacion} />
          <PublicRoute exact path="/como-transformamos/eventos-institucionales" component={EventosInstitucionales} />
          <PublicRoute
            exact
            path="/como-transformamos/investigacion/perfiles-historicos"
            component={PerfilesHistoricos}
          />
          <PublicRoute exact path="/como-transformamos/investigacion/seminario" component={Seminario} />
          <PublicRoute exact path="/como-transformamos/capacitacion-y-formacion" component={CapacitacionFormacion} />
          <PublicRoute exact path="/como-transformamos/redes-colaboracion" component={RedesColaboracion} />
          <PublicRoute exact path="/como-transformamos/biblioteca-digital" component={BibliotecaDigital} />
          <PublicRoute exact path="/como-transformamos/evaluacion" component={EvaluacionCertificacion} />

          <PublicRoute exact path="/seminario" component={Seminario} />

          <PublicRoute exact path="/noticias" component={Noticias} />

          <PublicRoute exact path="/blog" component={Blog} />
          <PublicRoute path="/blog/:slug" component={Post} />

          <PublicRoute exact path="/contacto" component={Contacto} />

          {/* <PublicRoute exact path="/calendario-actividades" component={CalendarioCeead} /> */}

          <PublicRoute exact path="/legales" component={Legales} />
          <PublicRoute exact path="/legales/aviso-privacidad" component={AvisoPrivacidad} />
          <PublicRoute exact path="/legales/terminos-y-condiciones" component={TerminosCondiciones} />
          <PublicRoute
            exact
            path="/legales/politica-reembolsos-cancelaciones"
            component={PoliticaReembolsosCancelaciones}
          />

          <PublicRoute path="/newsletter/confirm/:token" component={VerificarSuscripcionBoletin} />
          <PublicRoute exact path="/faq" component={PreguntasFrecuentes} />
          <Route exact path="/brochure-diplomado" component={() => <Home redirect="pdfDiplomado" />} />
          <Route exact path="/guia_pedagogica_CEC" component={() => <Home redirect="guia_pedagogica_CEC" />} />

          <Route
            path="/login"
            component={() => {
              window.location.href = ACCOUNTS_PATH + LOGIN_PATH;
              return null;
            }}
          />
          <Route
            path="/register"
            component={() => {
              window.location.href = ACCOUNTS_PATH + REGISTER_PATH;
              return null;
            }}
          />

          <Redirect to="/" />

          {/* <PrivateRoute */}
          {/*    path="/" */}
          {/*    component={ DashboardRoutes } */}
          {/*    isAuthenticated={ user.logged } */}
          {/* /> */}
        </Switch>
        <Footer />
      </>
    </Router>
  );
};
